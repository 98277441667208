<template>
  <div class="main-bg-color h-100vh d-flex justify-content-center">
    <div class="shipment-card">
      <div v-if="isLoading">
        <OtherLoading variant="warning" />
      </div>
      <div v-else class="p-3">
        <font-awesome-icon
          @click="
            $router.push(
              `/my-shipment/${lang}?sessionId=${sessionId}&telephone=${telephone}${
                trackingNo ? `&trackingNo=${trackingNo}` : ``
              }`
            )
          "
          icon="arrow-left"
          class="text-light mb-2 cursor-pointer"
        />
        <div class="booking-container">
          <div class="header-container">
            <b-container class="p-3 m-0 mw-100">
              <b-row class="no-gutters">
                <b-col
                  cols="12"
                  class="d-flex justify-content-between flex-gap"
                >
                <div class="d-flex">
                  <div
                    class="icon-box"
                    v-bind:style="{
                      backgroundImage: 'url(' + detail.tracking.icon.iconUrl +')'
                    }"
                  ></div>
                  <p class="f-size-16 font-size-sm-14 mb-2 font-weight-bold flex-gap ml-3">
                    {{ detail.tracking.shipmentInfo.consignmentNo }}
                  </p>
                  </div>
                  <div class="f-size-16 font-size-sm-14 mb-2">
                    <span class="main-color font-weight-bold">{{
                      detail.tracking.shipmentStatus[0].statusDescription
                    }}</span>
                  </div>
                </b-col>
                <b-col
                  cols="12"
                  class="d-flex justify-content-between flex-gap"
                >
                  <p class="mb-0 f-size-14 font-size-sm-12 text-color-province">
                    {{ $t("estimateToArrive") }}
                  </p>
                  <p class="mb-0 f-size-14 font-size-sm-12 text-color-province">
                    {{
                      detail.tracking.shipmentStatus[0].statusDateTime
                        | moment($formatDateTime)
                    }}
                  </p>
                </b-col>
              </b-row>
            </b-container>
          </div>
          <b-container class="p-3 m-0 mw-100 header-container">
            <b-row class="no-gutters">
              <b-col class="col-container" cols="5">
                <p class="f-size-14 font-size-sm-12 mb-3">{{ $t("sender") }}</p>
                <p
                  class="
                    font-weight-bold
                    f-size-14
                    font-size-sm-12
                    text-break text-center
                    mb-1
                  "
                >
                  {{ detail.tracking.shipmentInfo.senderName }}
                </p>
                <p class="mb-0 f-size-12 font-size-sm-10 text-color-province">
                  {{ `${detail.tracking.shipmentInfo.originProvince}` }}
                </p>
                <p class="mb-0 f-size-12 font-size-sm-10">
                  {{ detail.tracking.shipmentInfo.senderMobile }}
                </p>
              </b-col>
              <b-col class="col-container" cols="2">
                <font-awesome-icon
                  class="text-color-kerry f-size-22"
                  icon="arrow-alt-circle-right"
                />
              </b-col>
              <b-col class="col-container" cols="5">
                <p class="f-size-14 font-size-sm-12 mb-3">
                  {{ $t("recipient") }}
                </p>
                <p
                  class="
                    font-weight-bold
                    f-size-14
                    font-size-sm-12
                    text-break text-center
                    mb-1
                  "
                >
                  {{ detail.tracking.shipmentInfo.recipientName }}
                </p>
                <p class="mb-0 f-size-12 font-size-sm-10 text-color-province">
                  {{
                    `${detail.tracking.shipmentInfo.destinationProvince} ${detail.tracking.shipmentInfo.destinationPostalCode}`
                  }}
                </p>
                <p class="mb-0 f-size-12 font-size-sm-10">
                  {{ detail.tracking.shipmentInfo.recipientMobile }}
                </p>
              </b-col>
            </b-row>
          </b-container>
          <div class="p-3">
            <span class="f-size-14 font-size-sm-12">Link: </span
            ><a
              class="f-size-14 font-size-sm-12"
              target="_blank"
              :href="detail.linkTracking"
              >{{ detail.linkTracking }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OtherLoading from "@/components/other/OtherLoading";
export default {
components: { OtherLoading },
name:"MyShipmentDetail",
data: function(){
  return{
    lang: "",
    isLoading: true,
    trackingNo:"",
    sessionId: "",
    userGUID: "",
    trackingNoSelected: "",
    detail:{}
  }
},
created: async function(){
  const {sessionId,trackingNo,telephone,trackingNoSelected} = this.$route.query
  this.lang = this.$route.params.lang ? this.$route.params.lang : "th"
  this.trackingNoSelected = trackingNoSelected
  this.trackingNo = trackingNo
  this.sessionId = sessionId
  this.telephone = telephone
  if(this.sessionId){
    await this.getUserGUID()
    await this.getBookingDetail()
  } else {
    this.$router.push('/session-expired')
  }
},
methods: {
  getUserGUID: async function(){
    const getUserUrl = `${this.$baseUrl}/kerryApi/user/${this.sessionId}`
    const {result,error} = await this.handlePromise(this.$axios.get(getUserUrl))
    if (error) {
      console.log(error)
      return
    }
    if(result.data.result === 1){
      this.userGUID = result.data.detail.userGUID
      if(!this.userGUID){
        this.$router.push('/session-expired')
      }else if (!result.data.detail.isLogin){
        const redirectUrl = encodeURIComponent(
          `/my-shipment/details/${this.lang}?sessionId=${this.sessionId}${
                this.trackingNo ? `&trackingNo=${this.trackingNo}` : ``
              }&telephone=${this.telephone}&trackingNoSelected=${this.trackingNoSelected}`
        );
        this.$router.push(`/login?sessionId=${this.sessionId}&redirectUrl=${redirectUrl}`);
      }
    }
  },
  getBookingDetail: async function(){
    const url = `${this.$baseUrl}/kerryApi/tracking/${this.trackingNoSelected}`
    const {result,error} = await this.handlePromise(this.$axios.get(url))
      if (error) {
        console.log(error)
        return
      }
      if(result.data.result == 1){
        this.detail = result.data.detail
        this.isLoading = false
      }
  },
  handlePromise: function(promise) {
    return promise.then(data => {
      return {
        error:null,
        result: data
      }
    })
    .catch(err => {
      return {
        error: err
      }
    })
  }
}
}
</script>

<style lang="scss" scoped>
.header-container {
  border-bottom: 1px solid #bebebe;
}

.booking-container {
  background-color: #ffffff;
  border-radius: 8px;
  margin-bottom: 2rem;
}

.col-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-gap {
  gap: 0.7rem;
  align-items: center;
}

.cursor-pointer {
  cursor: pointer;
}
.icon-box {
    background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  width: 17px;
  height: 21px;
}

@media only screen and (max-width: 576px) {
  .font-size-sm-14 {
    font-size: 14px;
  }

  .font-size-sm-12 {
    font-size: 12px;
  }

  .font-size-sm-10 {
    font-size: 10px;
  }
}
</style>